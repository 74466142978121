<template>
  <div class="login_container">
    <RegisterInner v-if="defaultPage"></RegisterInner>
    <RegisterInnerBv v-if="is8V"></RegisterInnerBv>
  </div>
</template>
<script>
import { VUE_UNION_STATION } from "@/assets/js/stationSetting";
import { translateTitle } from "@/assets/js/i18n";
import { mapActions, mapGetters } from "vuex";
import RegisterInner from "@/pages/login/register-inner.vue";
import RegisterInnerBv from "@/pages/login/register-inner-bv.vue";
export default {
  mixins: [],

  data() {
    return {
      is8V: false,
      defaultPage: false,
    };
  },
  components: { RegisterInner, RegisterInnerBv },
  computed: {
    // ...mapGetters({
    //   darkMode: "user/darkMode"
    // })
  },
  created() {
    // this.defaultPage = true;
    if (VUE_UNION_STATION == "8V") {
      this.is8V = true;
    } else {
      this.defaultPage = true;
    }
  },
  beforeDestroy() {},
  destroyed() {},
  mounted() {},
  methods: {
    translateTitle,
  },
};
</script>
<style lang="scss"></style>
